import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import NavThree from "../components/NavThree";
import ProjectDetails from "../components/ProjectDetails";
import {graphql} from "gatsby";

export const query = graphql`
    query($id: String!){
        allStrapiProjects(filter: {slug: {eq: $id}}) {
            nodes {
                dificulty {
                    id
                    text
                }
                language
                project_type {
                    type
                    identifier
                }
                title
                url
                description
                subtitle
                image {
                    localFile{
                        sharp: childImageSharp {
                            fluid( maxWidth: 770, maxHeight: 447, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            totalCount
        }
        allStrapiProjectVersions(sort: {order: DESC, fields: created_at}, filter: {project: {slug: {eq: $id}}}) {
            nodes {
                id
                title
                description
                verson
            }
        }
        allStrapiProjectRoadmaps (sort: {order: DESC, fields: created_at}, filter: {project: {slug: {eq: $id}}}) {
            nodes {
                title
                description
            }
        }
    }
`
const ProjectPage = ({data}) => {
    const currProject = data.allStrapiProjects.nodes[0]
    return (
        <Layout pageTitle="Kipso | Course Details">
            <NavThree/>
            <PageHeader title={currProject.title}/>
            <ProjectDetails
                project={currProject}
                changelog={data.allStrapiProjectVersions.nodes}
                roadmaps={data.allStrapiProjectRoadmaps.nodes}
            />
            <Footer/>
        </Layout>
    );
};

export default ProjectPage;
