import React, {useState} from "react";
import Img from "gatsby-image";

const ProjectDetails = ({project, changelog,roadmaps}) => {
    const [isSumary, setIsSumary] = useState(true);
    const [isRoadMap, setIsRoadMap] = useState(false);
    const [isChangelog, setIsChangelog] = useState(false);

    const changeActiveTab = (newActive) => {
        setIsSumary(false)
        setIsChangelog(false)
        setIsRoadMap(false)

        if (newActive === "sumary") {
            setIsSumary(true)
        } else if (newActive === "changelog") {
            setIsChangelog(true)
        } else {
            setIsRoadMap(true)
        }
    }
    return (
        <section className="course-details">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-xl-8 ">
                        <div className="course-details__content">
                            <div className="course-details__top">
                                <div className="course-details__top-left">
                                    <h2 className="course-details__title">
                                        {project.subtitle}
                                    </h2>
                                </div>
                                <div className="course-details__top-right">
                                    <a href="#none"
                                       className={"course-one__category " + project.project_type.identifier}>
                                        {project.project_type.type}
                                    </a>
                                </div>
                            </div>
                            <div className="course-one__image">
                                <Img fluid={{...project.image.localFile.sharp.fluid}} alt={project.title}/>
                            </div>

                            <ul className="course-details__tab-navs list-unstyled">
                                <li>
                                    <a
                                        type="button"
                                        className={isSumary ? "active" : ""}
                                        onClick={() => {
                                            changeActiveTab("sumary")
                                        }}
                                    >
                                        Resumen
                                    </a>
                                </li>
                                <li>
                                    <a
                                        type="button"
                                        className={isChangelog ? "active" : ""}
                                        onClick={() => {
                                            changeActiveTab("changelog")
                                        }}
                                    >
                                        Changelog
                                    </a>
                                </li>
                                <li>
                                    <a
                                        type="button"
                                        className={isRoadMap ? "active" : ""}
                                        onClick={() => {
                                            changeActiveTab("roadmap")
                                        }}>
                                        Roadmap
                                    </a>
                                </li>
                            </ul>
                            <div className=" course-details__tab-content ">
                                <div
                                    dangerouslySetInnerHTML={{__html: project.description}}
                                    className={isSumary ? "animated fadeInUp show active" : "displayNone"}
                                    id="summary"
                                />
                                <div
                                    className={isChangelog ? "animated fadeInUp show active" : "displayNone"}
                                    id="changelog"
                                >
                                    <ul className="course-details__curriculum-list list-unstyled">
                                        {changelog.map(version =>
                                            <>
                                                <li key={version.id}>
                                                    <div className="course-details__curriculum-list-left">
                                                        <div className="course-details__meta-icon video-icon">
                                                            <i className="fas fa-file-code"></i>
                                                        </div>
                                                        <a href="#none">{version.title}</a>{" "}
                                                    </div>
                                                    <div className="course-details__curriculum-list-right">
                                                        {version.verson}
                                                    </div>
                                                </li>
                                                <p className="course-details__tab-text">
                                                    {version.description}
                                                </p>
                                            </>
                                        )}
                                    </ul>
                                </div>
                                <div
                                    className={isRoadMap ? "animated fadeInUp show active " : "displayNone"}
                                    id="roadmap"
                                    style={{marginTop: -15+"px"}}
                                >
                                    {roadmaps.map(roadmap=>
                                        <div className="course-details__comment">
                                            <div className="course-details__comment-single">
                                                <div className="course-details__comment-top">
                                                    <div className="course-details__comment-right">
                                                        <h2 className="course-details__comment-name">
                                                            {roadmap.title}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <p className="course-details__comment-text">
                                                    {roadmap.description}
                                                </p>
                                            </div>
                                        </div>)}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-4">
                        <div className="course-details__meta">
                            <a href={project.url} className="course-details__meta-link">
                <span className="course-details__meta-icon">
                  <i className="far fa-folder-open"></i>
                </span>
                                {project.url}
                            </a>
                            <a href="#none" className="course-details__meta-link">
                <span className="course-details__meta-icon">
                  <i className="far fa-user-circle"></i>
                </span>
                                Nivel: <span>{project.dificulty.text}</span>
                            </a>
                            <a href="#none" className="course-details__meta-link">
                <span className="course-details__meta-icon">
                  <i className="far fa-bell"></i>
                </span>
                                Stack: <span>{project.language}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectDetails;
